.input {
	--input-transition: 0.25s var(--ease-in-out-quad);
	--border-color: currentColor;
	--input-text-color: var(--text-color);
	--input-error-color: var(--error-color);
	--input-font-family: var(--font-system);
	--input-margin-vertical: var(--spacing)
}

.input:not(.input--hidden) + .input {
		--input-margin-vertical: 0;
	}

.input--highlighted {
		--border-color: var(--error-color);
	}

.input--disabled {
		--border-color: transparent;
	}

.input--hidden {
		display: none !important;
	}

.input--text {
		display: grid;
		grid-template-areas: 'label label' 'tag show-password' 'error error';
		grid-template-columns: 1fr auto;
		/* no margin bottom needed, as error container has the var(--spacing) as min-height */
		margin: var(--input-margin-vertical) auto 0;
		max-width: var(--input-width);
		transition: border-color var(--input-transition)
	}

.input--text::after {
			content: '';
			display: block;
			grid-area: error;
			align-self: start;
			border-bottom: 2px solid var(--border-color);
		}

.input--text .input__label {
			grid-area: label;
		}

.input--text .input__error {
			display: flex;
			justify-self: end;
			align-items: center;
			grid-area: error;
			pointer-events: none;
			color: var(--error-color);
			font-size: var(--font-size-s);
			transition: opacity var(--input-transition);
			min-height: var(--spacing)
		}

.input--text .input__error:empty::before {
					content: '.';
					display: block;
					opacity: 0;
				}

.input--text .input__tag {
			grid-area: tag;
			color: var(--text-color);
			font-family: var(--input-font-family);
			background-color: transparent;
			border: none;
			-webkit-appearance: none;
			        appearance: none;
			outline: none;
			padding: 0.5em 0 0.25em;
			overflow: hidden;
			text-overflow: ellipsis
		}

.input--text .input__tag::-webkit-calendar-picker-indicator,
			.input--text .input__tag::-webkit-inner-spin-button {
				display: none;
			}

.input--text .input__tag {

			/* remove background color from autofill input */
		}

.input--text .input__tag:-webkit-autofill,
			.input--text .input__tag:-webkit-autofill:hover,
			.input--text .input__tag:-webkit-autofill:focus,
			.input--text .input__tag:-webkit-autofill:active {
				animation: autofill 0s forwards;
			}

.input--text .input__delete {
			--delete-opacity: 0;
			--delete-pointer-events: none;
			grid-area: tag;
			display: block;
			position: relative;
			align-self: center;
			justify-self: end;
			place-self: center end;
			width: 1rem;
			height: 1rem;
			margin-left: 1rem;
			border-radius: 100%;
			background-color: var(--color-blue--medium);
			transition: var(--input-transition);
			transition-property: opacity, background-color;
			opacity: var(--delete-opacity);
			pointer-events: var(--delete-pointer-events);
			cursor: pointer
		}

.input--text .input__delete::before,
			.input--text .input__delete::after {
				content: '';
				display: block;
				position: absolute;
				top: 50%;
				left: 50%;
				background-color: var(--color-white);
				width: 0.5625rem;
				height: 1px;
			}

.input--text .input__delete::before {
				transform: translateX(-50%) translateY(-50%) rotate(-45deg);
			}

.input--text .input__delete::after {
				transform: translateX(-50%) translateY(-50%) rotate(45deg);
			}

.input--dirty.input--text .input__delete {
				--delete-opacity: 1;
				--delete-pointer-events: all;
			}

.input--disabled.input--text .input__delete {
				--delete-opacity: 0;
				--delete-pointer-events: none;
			}

.input__show-password {
		grid-area: show-password;
		transition: opacity var(--input-transition);
	}

.input--disabled .input__show-password {
			opacity: 0;
			pointer-events: none;
		}

.input__show-password.button {
			margin-left: 1em;
		}

@keyframes autofill {
	100% {
		background: transparent;
		color: inherit;
	}
}

.button {
	--font-size-button: var(--font-size-m);
	--font-family-button: var(--font-alternative-medium);
	--button-height: 4rem;
	--button-transition: 0.25s var(--ease-in-out-quad);
	--hover-color: var(--color-black);
	--box-shadow: none;
	--button-text-color: var(--color-white);
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: var(--font-size-button);
	height: max-content;
	min-height: var(--button-height);
	color: var(--button-text-color);
	background-color: transparent;
	border: none;
	cursor: pointer;
	border-radius: var(--button-height);
	padding: 0 calc(var(--spacing) * 2);
	outline: none;
	margin: var(--spacing) auto;
	transition: var(--button-transition);
	transition-property: box-shadow, transform, opacity;
	position: relative;
	z-index: 0;
	box-shadow: var(--box-shadow)
}

.button[disabled] {
		pointer-events: none;
		--box-shadow: none
	}

.button[disabled]::after {
			opacity: 0.7;
		}

.button::after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 10;
		background-color: inherit;
		opacity: 0;
		transition: opacity var(--button-transition);
		border-radius: var(--button-height);
		pointer-events: none;
	}

.button::before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 0;
		background-color: var(--hover-color);
		opacity: 0;
		transition: opacity var(--button-transition);
		border-radius: var(--button-height);
		pointer-events: none;
	}

.button span {
		position: relative;
		z-index: 10;
		pointer-events: none;
		font-family: var(--font-family-button);
	}

.button--primary {
		background: var(--color-cyan);
		--box-shadow: var(--box-shadow-bottom--4-steps);
		transform: translateY(0)
	}

.button--primary:hover {
			transform: translateY(-2px);
			--box-shadow: var(--box-shadow-bottom--5-steps);
		}

.button--primary:active {
			transform: translateY(1px);
			--box-shadow: var(--box-shadow-bottom--3-steps);
		}

.button--secondary {
		--button-text-color: var(--color-blue--medium);
		--box-shadow: 0 0 0 2px var(--text-color) inset
	}

.button--secondary:hover::before {
				opacity: 0.15;
			}

.button--secondary:active::before {
				opacity: 0.3;
			}

.button--small {
		--button-height: 2rem;
		--spacing: var(--spacing--small);
		--font-size-button: var(--font-size-s);
	}

.button--text {
		--button-text-color: var(--color-blue--medium);
		--font-family-button: var(--font-alternative-light);
		transition-property: transform, opacity;
		--spacing: 0;
	}

.button a,
	.button a:visited,
	.button a:focus {
		text-decoration: none;
		color: var(--button-text-color);
		font-family: var(--font-family-button)
	}

.button a::before, .button a:visited::before, .button a:focus::before {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
		}

.error {
	--error-transition: 0.25s var(--ease-in-out-quad);
	display: grid;
	max-width: var(--input-width);
	color: var(--error-color);
	transition: opacity var(--error-transition);
	grid-template-columns: min-content 1fr;
	grid-template-areas: 'icon message';
	align-items: center;
	opacity: 0;
}

.error--visible {
		opacity: 1;
	}

.error__messages {
		grid-area: message;
		font-weight: var(--font-system-bold);
	}

.error__icon {
		width: 3.5rem;
		height: 3.5rem;
		grid-area: icon;
		align-self: center;
		justify-self: end;
	}

.modal {
	--modal-animation-duration: 0.4s;
	--modal-animation-timing-function: var(--ease-in-out-quad);
	--modal-background-color: var(--color-blue--medium);
	--modal-close-icon-color: var(--color-cyan);
	background: var(--modal-background-color);
	width: calc(100vw - var(--spacing) * 2);
	max-width: var(--modal-window-max-width);
	max-height: calc(100vh - var(--spacing) * 2);
	border-radius: 2rem;
	border: none;
	padding: 0;
	box-shadow: var(--box-shadow-bottom--5-steps);
	animation-duration: var(--modal-animation-duration);
	animation-timing-function: var(--ease-in-out-quad);
	grid-template-areas: 'top' 'center' 'bottom';
	grid-template-rows: min-content minmax(0, 100%) min-content;
	position: fixed;
	overflow: hidden;
	height: auto;
	top: 50%;
	transform: translateY(-50%)
}

.modal[open] {
		display: grid;
		animation-name: show-dialog
	}

.modal[open]::-webkit-backdrop {
			animation-name: show-backdrop;
		}

.modal[open]::backdrop {
			animation-name: show-backdrop;
		}

.modal.hide {
		animation-name: hide-dialog
	}

.modal.hide::-webkit-backdrop {
			animation-name: hide-backdrop;
		}

.modal.hide::backdrop {
			animation-name: hide-backdrop;
		}

.modal::-webkit-backdrop {
		background-color: transparent;
		animation-name: none;
		/* using a custom property to set the duration does not work somehow */
		animation-duration: 0.4s;
		animation-fill-mode: forwards;
		animation-timing-function: var(--modal-animation-timing-function);
	}

.modal::backdrop {
		background-color: transparent;
		animation-name: none;
		/* using a custom property to set the duration does not work somehow */
		animation-duration: 0.4s;
		animation-fill-mode: forwards;
		animation-timing-function: var(--modal-animation-timing-function);
	}

.modal__header {
		grid-area: top;
		display: grid;
		grid-template-columns: 1fr auto 1fr;
		padding: var(--spacing);
		align-items: center;
		color: var(--color-white);
	}

.modal__content {
		--line-color: var(--color-white);
		grid-area: center;
		overflow: auto;
		overscroll-behavior: contain;
		color: var(--color-white);
	}

.modal__title {
		grid-column: 2 / 3;
		grid-row: 1 / -1;
		text-align: center;
	}

.modal__close {
		margin-left: var(--spacing--small);
		position: relative;
		cursor: pointer;
		grid-column: 3 / 4;
		grid-row: 1 / -1;
		justify-self: end
	}

.modal__close::before {
			content: '';
			position: absolute;
			top: calc(var(--spacing) * -1);
			right: calc(var(--spacing) * -1);
			bottom: calc(var(--spacing) * -1);
			left: calc(var(--spacing) * -1);
			z-index: 10;
		}

.modal__button {
		position: sticky;
		bottom: 0;
		width: 100%;
		margin-top: 0;
		margin-bottom: 0;
		background-color: var(--color-cyan);
		box-shadow: none;
		border: 1px solid var(--modal-background-color);
		color: var(--color-white)
	}

.modal__button:hover,
		.modal__button:active {
			transform: none;
		}

.modal__success-icon {
		margin: var(--spacing) auto;
		display: block;
		width: 15em;
		/* max-width: var(--modal-window-max-width); */
		/* max-height: 50vh; */
		--checkbox-stroke-width: 6px;
		--checkbox-stroke-color: var(--color-cyan);
		--checkbox-delay: 500ms;
		--checkbox-duration: 3s;
		--checkbox-timing-function: var(--ease-in-out-quad);
		/* TODO: use dynamically calculated path lenght here */
		--checkbox-path-length: 10000;
		--checkmark-visibility-start: 0;
	}

.modal__success-icon circle,
		.modal__success-icon polyline {
			stroke-dashoffset: var(--checkbox-path-length);
			animation-name: successAnimation;
			animation-delay: var(--checkbox-delay);
			animation-duration: var(--checkbox-duration);
			animation-timing-function: var(--checkbox-timing-function);
			animation-fill-mode: forwards;
			stroke-dasharray: var(--checkbox-path-length);
		}

.modal__success-icon polyline {
			animation-delay: calc(var(--checkbox-delay) * 2.5);
			stroke-linejoin: round;
			stroke-linecap: round;
		}

@keyframes show-dialog {
	from {
		opacity: 0;
		transform: translateY(-50%) translateY(2rem);
	}

	to {
		opacity: 1;
		transform: translateY(-50%);
	}
}

@keyframes hide-dialog {
	to {
		opacity: 0;
		transform: translateY(-50%) translateY(2rem);
	}
}

@keyframes show-backdrop {
	from {
		-webkit-backdrop-filter: blur(0);
		        backdrop-filter: blur(0);
	}

	to {
		-webkit-backdrop-filter: blur(10px);
		        backdrop-filter: blur(10px);
	}
}

@keyframes hide-backdrop {
	from {
		-webkit-backdrop-filter: blur(10px);
		        backdrop-filter: blur(10px);
	}

	to {
		-webkit-backdrop-filter: blur(0);
		        backdrop-filter: blur(0);
	}
}

@keyframes successAnimation {
	from {
		stroke-dashoffset: var(--checkbox-path-length);
	}

	to {
		stroke-dashoffset: 0;
	}
}

/* polyfill */

.modal[role='dialog'] {
	left: 0;
	right: 0;
	margin: auto
}

.modal[role='dialog']:not([open]) {
		display: none;
	}

.modal[role='dialog'] + .backdrop {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: transparent;
		animation-name: none;
		/* using a custom property to set the duration does not work somehow */
		animation-duration: 0.4s;
		animation-fill-mode: forwards;
		animation-timing-function: var(--modal-animation-timing-function);
	}

.modal[role='dialog'][open] + .backdrop {
			animation-name: show-backdrop;
		}

.modal[role='dialog'].hide + .backdrop {
			animation-name: hide-backdrop;
		}

._dialog_overlay {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.tooltip {
	--tooltip-icon-color: var(--color-blue--medium);
	display: inline-flex;
	vertical-align: text-top;
	background-color: transparent;
	border: none;
	cursor: pointer;
	outline: none;
	align-content: center;
	justify-content: center;
	place-content: center;
	padding: 0 var(--spacing--small);
}

.countdown {
	--bar-height: 0.2rem;
	--bar-transition-time: var(--transition-time);
	--bar-color: var(--color, var(--color-cyan));
	--bar-transition-origin: var(--transform-origin, left);
	--bar-radius: var(--radius);
}

.countdown__progress {
		height: var(--bar-height);
		overflow: hidden;
		width: 100%;
		position: relative;
		margin: var(--spacing--small) 0
	}

.countdown__progress::after {
			content: '';
			position: absolute;
			height: 100%;
			border-radius: var(--bar-radius);
			width: 100%;
			background-color: var(--bar-color);
			transform: scaleX(var(--countdown-progress));
			transform-origin: var(--bar-transition-origin);
			transition: var(--bar-transition-time) linear;
			left: 0;
		}

.countdown__progress::before {
			content: '';
			border-radius: var(--bar-radius);
			position: absolute;
			height: 100%;
			width: 100%;
			background-color: var(--bar-color);
			opacity: 0.1;
			left: 0;
		}

.version-number {
	font-size: var(--font-size-xs);
	color: var(--color-white);
	display: flex;
	align-self: flex-end;
	opacity: 0.2;
	padding: var(--spacing--small) var(--spacing) 0;
}

.captcha {
	margin: var(--spacing) auto;
	display: flex;
	justify-content: center;
}

.main {
	display: flex;
	flex-direction: column;
	margin-top: var(--header-height);
	padding: var(--spacing);
	min-height: var(--main-height);
	max-height: var(--main-height);
	max-width: 600px;
	margin-left: auto;
	margin-right: auto;
}

.main > * {
		flex: 1;
	}

.generate-tan {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.generate-tan__current-tan {
		-webkit-user-select: all;
		        user-select: all;
		display: inline-block;
		text-align: center;
		font-feature-settings: "tnum";
		font-variant-numeric: tabular-nums;
		opacity: 1;
		transform: translateX(0);
		transition: 0.25s var(--ease-in-out-quad);
		transition-property: opacity, transform;
	}

.generate-tan__current-tan--hide {
			opacity: 0;
			transform: translateX(0.25rem);
		}

.generate-tan__countdown {
		width: 6ch;
	}

.generate-tan__button {
		width: 16rem;
		transition-property: box-shadow, transform, opacity, background-color;
		padding: 0;
	}

.generate-tan__button--active {
			background-color: var(--color-blue--medium);
		}

.generate-tan__button span {
			display: flex;
			align-items: center;
			justify-items: center;
			place-items: center
		}

.generate-tan__button span svg {
				position: absolute;
				width: 2rem;
				left: 100%;
				margin-left: var(--spacing--small);
				--checkbox-stroke-width: 2px;
				--checkbox-stroke-color: var(--color-cyan);
				--checkbox-duration: 0.5s;
				--checkbox-timing-function: var(--ease-in-out-quad);
				/* TODO: use dynamically calculated path lenght here */
				--checkbox-path-length: 100;
				--checkmark-visibility-start: 0;
			}

.generate-tan__button span svg circle {
					display: none;
				}

.generate-tan__button span svg polyline {
					animation-name: checkAnimation;
					stroke-dashoffset: var(--checkbox-path-length);
					animation-duration: var(--checkbox-duration);
					animation-timing-function: var(--checkbox-timing-function);
					animation-delay: 0.25s;
					animation-fill-mode: forwards;
					stroke-dasharray: var(--checkbox-path-length);
					stroke-linejoin: round;
					stroke-linecap: round;
				}

@keyframes checkAnimation {
	from {
		stroke-dashoffset: 100;
	}

	to {
		stroke-dashoffset: 0;
	}
}

/* Box sizing rules */

*,
*::before,
*::after {
	box-sizing: border-box;
}

/* Remove default padding */

ul[class],
ol[class] {
	padding: 0;
}

/* Remove default margin */

body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
	margin: 0;
}

html {
	font-size: 16px;
}

/* Set core body defaults */

body {
	/* min-height: 100vh;
	scroll-behavior: smooth; */
}

/* Remove list styles on ul, ol elements with a class attribute */

ul[class],
ol[class] {
	list-style: none;
}

/* A elements that don't have a class get default styles */

a:not([class]) {
	text-decoration-skip-ink: auto;
}

/* Make images easier to work with */

img {
	max-width: 100%;
	display: block;
}

/* Inherit fonts for inputs and buttons */

input,
button,
textarea,
select {
	font: inherit;
}

:root {
	/* fonts */
	--font-alternative-medium: 'PrometoMedium';
	--font-alternative-light: 'PrometoLight';

	--font-system: 'Verdana', 'Geneva', 'sans-serif';
	--font-system-bold: 700;
	--font-system-regular: 400;
	--font-system-light: 100;

	--inner-width: 800px;

	/* font-sizes */
	--font-size-xxl: 4.375rem;
	--font-size-xl: 3.375rem;
	--font-size-l: 2rem;
	--font-size-m: 1.25rem;
	--font-size-default: 1rem;
	--font-size-s: 0.81rem;
	--font-size-xs: 0.6rem;

	/* line height */
	--line-height-spaced: 1.333;

	/* element-sizes */
	--input-width: 30rem;
	--min-touch-width: 2.75rem;
	--modal-window-max-width: 32rem;

	/* colors */
	--color-blue: rgb(17, 121, 191);
	--color-blue--medium: rgb(0, 77, 122);
	--color-blue--dark: rgb(2, 33, 52);
	--color-blue--super-dark: rgb(0, 16, 36);

	--color-cyan: rgb(0, 171, 231);
	--color-cyan--medium: rgb(129, 188, 223);
	--color-cyan--light: rgb(192, 222, 239);
	--color-red: rgb(221, 12, 41);
	--color-magenta: rgb(229, 0, 75);
	--color-white: rgb(255, 255, 255);
	--color-black: rgb(0, 0, 0);
	--color-grey: rgb(127, 127, 127);
	--color-grey--light: rgb(191, 191, 191);
	--color-grey--faded: rgb(0, 0, 0);
	--color-black-transparent: rgba(255, 255, 255, 0.15);

	--text-color: var(--color-blue--medium);
	--line-color: var(--color-blue--medium);
	--error-color: var(--color-red);

	/* miscellaneous */
	--spacing: 1.5rem;
	--spacing--small: 0.5rem;
	--border-radius: 0.5rem;
	--margin: var(--spacing);
	--padding: var(--spacing);
	--header-height: 6.25rem;

	/* default SVG Color */
	--svg-color: var(--color-cyan);
}

@supports (padding: env(safe-area-inset-top)) {

:root {
		--header-height: calc(6.25rem + env(safe-area-inset-top));
}
	}

:root {

	--main-height: calc(100vh - var(--header-height));
}

@supports (padding: env(safe-area-inset-top)) {

:root {
		--main-height: calc(100vh - var(--header-height) - env(safe-area-inset-bottom));
}
	}

:root {

	/* transitions */
	--transition-duration: 0.5s;
	--ease-out-back: cubic-bezier(0.175, 0.885, 0.32, 1.275);
	--ease-out-back-less: cubic-bezier(0.175, 0.885, 0.32, 1.05);
	--ease-in-cubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
	--ease-out-cubic: cubic-bezier(0.215, 0.61, 0.355, 1);
	--ease-in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
	--ease-back: cubic-bezier(0.68, -0.55, 0.265, 1.55);

	--transition: var(--transition-duration);

	/* shadows */
	--box-shadow-bottom--3-steps: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.1),
		0 4px 4px rgba(0, 0, 0, 0.1);
	--box-shadow-bottom--4-steps: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.1),
		0 4px 4px rgba(0, 0, 0, 0.1), 0 8px 8px rgba(0, 0, 0, 0.1);
	--box-shadow-bottom--5-steps: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.1),
		0 4px 4px rgba(0, 0, 0, 0.1), 0 8px 8px rgba(0, 0, 0, 0.1), 0 16px 16px rgba(0, 0, 0, 0.1);

	--box-shadow-right--5-steps: 1px 0 1px rgba(0, 0, 0, 0.1), 2px 0 2px rgba(0, 0, 0, 0.1),
		4px 0 4px rgba(0, 0, 0, 0.1), 8px 0 8px rgba(0, 0, 0, 0.1), 16px 0 16px rgba(0, 0, 0, 0.1);
}

@font-face {
    font-family: 'PrometoMedium';
    src: url('fonts/PrometoW04-Medium.woff2') format('woff2'),
        url('fonts/PrometoW04-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'PrometoLight';
    src: url('fonts/PrometoW04-Light.woff2') format('woff2'),
        url('fonts/PrometoW04-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

html {
	font-family: var(--font-system);
	font-weight: 300;
}

b.font-family-alternative,
	.font-family-alternative b,
	strong.font-family-alternative,
	.font-family-alternative strong {
		font-family: var(--font-alternative-medium);
		font-weight: 500;
	}

h1,h2,h3,h4,h5 {
	margin: 1em 0;
	text-align: center;
	font-family: var(--font-alternative-medium);
	font-weight: normal;
}

p {
	line-height: var(--line-height-spaced);
	font-size: var(--font-size-s)
}

p + p {
		margin-top: var(--spacing--small);
	}

.font-family-alternative--medium {
		font-family: var(--font-alternative-medium);
		font-weight: normal;
	}

.font-family-alternative--light {
		font-family: var(--font-alternative-light);
		font-weight: normal;
	}

h1,
h2 {
	font-size: var(--font-size-l);
}

h2,
h4 {
	font-family: var(--font-alternative-light);
}

h3,
h4 {
	font-size: var(--font-size-m);
}

a {
	color: currentColor;
}

.text-color-blue {
		color: var(--color-blue);
	}

.text-color-cyan {
		color: var(--color-cyan);
	}

.text-color-red {
		color: var(--color-red);
	}

.text-color-black {
		color: var(--color-black);
	}

.text-color-white {
		color: var(--color-white);
	}

.text-color-grey {
		color: var(--color-grey);
	}

.font-size-xxl {
		font-size: var(--font-size-xxl);
	}

.font-size-xl {
		font-size: var(--font-size-xl);
	}

.font-size-l {
		font-size: var(--font-size-l);
	}

.font-size-m {
		font-size: var(--font-size-m);
	}

.font-size-default {
		font-size: var(--font-size-default);
	}

.font-size-s {
		font-size: var(--font-size-s);
	}

.hr {
	background-color: var(--line-color);
	border: none;
	height: 0.125rem;
	margin: 0.5rem auto;
}

.hr--narrow {
		width: 15vw;
	}

.hr--thick {
		height: 0.25rem;
	}

html {
	overflow-x: hidden;
	overscroll-behavior: none;
	-webkit-tap-highlight-color: transparent;
}

body {
	line-height: var(--line-height-spaced);
	font-size: var(--font-size-s)
}

@supports (padding: env(safe-area-inset-bottom)) {

body {
		padding: 0 env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)
}
	}

body * {
		-webkit-user-select: var(--user-select, auto);
		        user-select: var(--user-select, auto);
	}

#root {
	display: contents;
	color: var(--text-color);
}

.flex {
	display: flex;
}

.flex--direction-column {
		flex-direction: column;
	}

.flex--direction-column-reverse {
			flex-direction: column-reverse;
		}

.flex--direction-row {
		flex-direction: row;
	}

.flex--direction-row-reverse {
			flex-direction: row-reverse;
		}

.flex--wrap {
		flex-wrap: wrap;
	}

.flex--justify-content-start {
			justify-content: flex-start;
		}

.flex--justify-content-end {
			justify-content: flex-end;
		}

.flex--justify-content-center {
			justify-content: center;
		}

.flex--justify-content-between {
			justify-content: space-between;
		}

.flex--justify-content-around {
			justify-content: space-around;
		}

.flex--justify-content-evenly {
			justify-content: space-evenly;
		}

.flex--align-items-start {
			align-items: flex-start;
		}

.flex--align-items-end {
			align-items: flex-end;
		}

.flex--align-items-center {
			align-items: center;
		}

.flex--align-items-stretch {
			align-items: stretch;
		}

.flex--align-content-start {
			align-content: flex-start;
		}

.flex--align-content-end {
			align-content: flex-end;
		}

.flex--align-content-center {
			align-content: center;
		}

.flex--align-content-stretch {
			align-content: stretch;
		}

.flex--align-content-between {
			align-content: space-between;
		}

.flex--align-content-around {
			align-content: space-around;
		}

.flex--align-self-start {
			align-self: flex-start;
		}

.flex--align-self-end {
			align-self: flex-end;
		}

.flex--align-self-center {
			align-self: center;
		}

.flex--align-self-stretch {
			align-self: stretch;
		}

.flex--align-self-baseline {
			align-self: baseline;
		}

.flex--justify-self-start {
			justify-self: flex-start;
		}

.flex--justify-self-end {
			justify-self: flex-end;
		}

.flex--justify-self-center {
			justify-self: center;
		}

.flex--justify-self-stretch {
			justify-self: stretch;
		}

.flex--justify-self-baseline {
			justify-self: baseline;
		}

.flex--grow {
		flex-grow: 1;
	}

.flex--shrink {
		flex-shrink: 1;
	}

.box {
	max-width: var(--input-width);
	margin: 0 auto;
	box-shadow: 0 0 0 1px var(--color-blue);
	background-color: rgba(0, 0, 0, 0.2);
	border-radius: var(--border-radius);
	padding: var(--spacing)
}

.box > *:first-child {
		margin-top: 0;
	}

.box > *:last-child {
		margin-bottom: 0;
	}

.form-view {
	display: grid;
	grid-template-areas: 'title' 'fields' 'additional-fields' 'error' 'submit';
	grid-template-rows: min-content min-content min-content min-content min-content;
}

.form-view__form {
		display: contents;
	}

.form-view__title {
		grid-area: title;
	}

.form-view__fields {
		grid-area: fields;
	}

.form-view__field {
		/* margin-left: 0; */
	}

.form-view__additional-fields {
		grid-area: additional-fields;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: var(--spacing);
	}

.form-view__error {
		grid-area: error;
		width: 100%;
		max-width: var(--input-width);
		margin: 0 auto;
	}

.form-view__submit {
		position: sticky;
		bottom: var(--spacing);
		margin-top: var(--spacing);
		grid-area: submit;
	}

.form-view__success-icon {
		margin: var(--spacing) auto;
		display: block;
		width: 30em;
		max-width: 50vw;
		max-height: 50vh;
		--checkbox-stroke-width: 6px;
		--checkbox-stroke-color: var(--color-cyan);
		--checkbox-delay: 500ms;
		--checkbox-duration: 3s;
		--checkbox-timing-function: var(--ease-in-out-quad);
		/* TODO: use dynamically calculated path lenght here */
		--checkbox-path-length: 10000;
		--checkmark-visibility-start: 0;
	}

.form-view__success-icon circle,
		.form-view__success-icon polyline {
			stroke-dashoffset: var(--checkbox-path-length);
			animation-name: checkmarkAnimation;
			animation-delay: var(--checkbox-delay);
			animation-duration: var(--checkbox-duration);
			animation-timing-function: var(--checkbox-timing-function);
			animation-fill-mode: forwards;
			stroke-dasharray: var(--checkbox-path-length);
		}

.form-view__success-icon polyline {
			animation-delay: calc(var(--checkbox-delay) * 2.5);
			stroke-linejoin: round;
			stroke-linecap: round;
		}

@keyframes checkmarkAnimation {
	from {
		stroke-dashoffset: var(--checkbox-path-length);
	}

	to {
		stroke-dashoffset: 0;
	}
}

.grid {
	display: grid;
}

.grid--justify-self-end {
			justify-self: end;
		}

.grid--center-scroll {
		width: calc(100% + 2 * var(--spacing));
		margin-left: calc(var(--spacing) * -1);
		grid-template-areas: 'top' 'center' 'bottom';
		grid-template-rows: min-content minmax(0, 100%) min-content;
	}

.grid--center-scroll .grid__top {
			grid-area: top;
			padding: 0 var(--spacing);
		}

.grid--center-scroll .grid__center {
			grid-area: center;
			overflow: hidden;
			padding: 0 var(--spacing);
		}

.grid--center-scroll .grid__bottom {
			grid-area: bottom;
			position: sticky;
			bottom: var(--spacing);
			margin-top: var(--spacing);
			padding: 0 var(--spacing);
		}

.hidden {
	display: none !important;
}

.pointer-events--none {
		pointer-events: none;
	}

.pointer-events--force-none {
		pointer-events: none !important
	}

.pointer-events--force-none * {
			pointer-events: none !important;
		}

/* css attribute is set in basic.css */

.user-select {
	--user-select: auto;
}

.user-select--text {
		--user-select: text;
	}

.user-select--none {
		--user-select: none;
	}

.user-select--none-force * {
				--user-select: none;
			}

.user-select--all {
		--user-select: all;
	}

.opacity--100 {
		opacity: 1;
	}

.opacity--50 {
		opacity: 0.5;
	}

.opacity--0 {
		opacity: 0;
	}

.font-weight--medium {
		font-family: var(--font-medium);
	}

.margin {
	margin: var(--margin);
}

.margin--vertical {
		margin-top: var(--margin);
		margin-bottom: var(--margin);
	}

.margin--right {
		margin-right: var(--margin);
	}

.margin--top {
		margin-top: var(--margin);
	}

.margin--bottom {
		margin-bottom: var(--margin);
	}

.margin--horizontal {
		margin-left: var(--margin);
		margin-right: var(--margin);
	}

.margin--small {
		--margin: var(--spacing--small);
	}

.margin--small-negative {
			--margin: calc(var(--spacing--small) * -1);
		}

.margin--large {
		--margin: calc(var(--spacing) * 2);
	}

.margin--large-negative {
			--margin: calc(var(--spacing--small) * -2);
		}

.margin--extra-large {
		--margin: calc(var(--spacing) * 3);
	}

.margin--extra-large-negative {
			--margin: calc(var(--spacing--small) * -3);
		}

.no-margin--top {
		margin-top: 0 !important;
	}

.no-margin--top > *:first-child {
			margin-top: 0 !important;
		}

.no-margin--bottom {
		margin-bottom: 0 !important;
	}

.no-margin--bottom > *:first-child {
			margin-bottom: 0 !important;
		}

.no-margin--left {
		margin-left: 0 !important;
	}

.no-margin--left > *:first-child {
			margin-left: 0 !important;
		}

.no-margin--right {
		margin-right: 0 !important;
	}

.no-margin--right > *:first-child {
			margin-right: 0 !important;
		}

.padding {
	padding: var(--padding);
}

.padding--vertical {
		padding-top: var(--padding);
		padding-bottom: var(--padding);
	}

.padding--right {
		padding-right: var(--padding);
	}

.padding--top {
		padding-top: var(--padding);
	}

.padding--bottom {
		padding-bottom: var(--padding);
	}

.padding--horizontal {
		padding-left: var(--padding);
		padding-right: var(--padding);
	}

.padding--small {
		--padding: var(--spacing--small);
	}

.padding--small-negative {
			--padding: calc(var(--spacing--small) * -1);
		}

.padding--large {
		--padding: calc(var(--spacing) * 2);
	}

.padding--large-negative {
			--padding: calc(var(--spacing--small) * -2);
		}

.padding--extra-large {
		--padding: calc(var(--spacing) * 3);
	}

.padding--extra-large-negative {
			--padding: calc(var(--spacing--small) * -3);
		}

.no-padding--top {
		padding-top: 0 !important;
	}

.no-padding--top > *:first-child {
			padding-top: 0 !important;
		}

.no-padding--bottom {
		padding-bottom: 0 !important;
	}

.no-padding--bottom > *:first-child {
			padding-bottom: 0 !important;
		}

.no-padding--left {
		padding-left: 0 !important;
	}

.no-padding--left > *:first-child {
			padding-left: 0 !important;
		}

.no-padding--right {
		padding-right: 0 !important;
	}

.no-padding--right > *:first-child {
			padding-right: 0 !important;
		}

.text--bold-spaced {
		font-size: var(--font-size-xs);
		font-weight: var(--font-system-bold);
		font-family: var(--font-system);
		text-transform: uppercase;
		letter-spacing: 0.28em;
	}

.text-align--left {
			text-align: left;
		}

.text-align--center {
			text-align: center;
		}

.text-align--right {
			text-align: right;
		}

.text-color-blue {
		color: var(--color-blue);
	}

.text-color-cyan {
		color: var(--color-cyan);
	}

.text-color-red {
		color: var(--color-red);
	}

.text-color-white {
		color: var(--color-white);
	}

.font-size-xxl {
		font-size: var(--font-size-xxl);
	}

&--black {
		color: var(--color-black);
	}

&--cyan {
		color: var(--color-blue);
	}

.font-size-xl {
		font-size: var(--font-size-xl);
	}

&--black {
		color: var(--color-black);
	}

&--cyan {
		color: var(--color-blue);
	}

.font-size-l {
		font-size: var(--font-size-l);
	}

&--black {
		color: var(--color-black);
	}

&--cyan {
		color: var(--color-blue);
	}

.font-size-m {
		font-size: var(--font-size-m);
	}

&--black {
		color: var(--color-black);
	}

&--cyan {
		color: var(--color-blue);
	}

.font-size-default {
		font-size: var(--font-size-default);
	}

&--black {
		color: var(--color-black);
	}

&--cyan {
		color: var(--color-blue);
	}

.font-size-s {
		font-size: var(--font-size-s);
	}

&--black {
		color: var(--color-black);
	}

&--cyan {
		color: var(--color-blue);
	}

.font-size-xs {
		font-size: var(--font-size-xs);
	}

&--black {
		color: var(--color-black);
	}

&--cyan {
		color: var(--color-blue);
	}

.header {
	position: fixed;
	display: grid;
	top: 0;
	left: 0;
	right: 0;
	height: var(--header-height);
	grid-template-columns: 15vw minmax(0, 100%) 15vw;
	grid-template-areas: 'left center right';
	justify-items: center;
	align-items: center;
	font-size: var(--font-size-m);
	z-index: 1000
}

.header::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(to bottom, rgb(255, 255, 255) 66%, rgba(255, 255, 255, 0) 100%);
		z-index: -1
	}

@supports (pointer-events: none) {

.header::before {
			pointer-events: none;
			height: 100%
	}
		}

@supports (padding: env(safe-area-inset-top)) {

.header {
		padding-top: env(safe-area-inset-top)
}
	}

.header .back-button {
		grid-area: left;
		align-self: stretch;
		justify-self: stretch;
	}

.header .navigation {
		grid-area: right;
		align-self: stretch;
		justify-self: stretch;
	}

.header .navigation-title {
		grid-area: center;
	}

/* .header-backdrop {
	position: fixed;
	z-index: 900;
	top: 0;
	left: 0;
	height: var(--header-height);
	width: 100%;
	backdrop-filter: blur(10px);
} */

.navigation {
	display: flex;
	font-family: var(--font-alternative-light);
}

.navigation__toggle {
		display: flex;
		flex: 1;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		z-index: 111
	}

.navigation__toggle:hover ~ .navigation__items {
				will-change: transform, box-shadow;
			}

.navigation__toggle:hover ~ .navigation__background-toggle {
				will-change: backdrop-filter;
			}

.navigation .navigation__hamburger {
		--hamburger-bar-height: 2px;
		--hamburger-bar-width: 26px;
		--hamburger-bar-color: var(--color-cyan);
		height: var(--hamburger-bar-height);
		width: var(--hamburger-bar-width);
		background-color: var(--hamburger-bar-color);
		position: relative;
		border-radius: 1rem;
		transition: 0.4s;
		transition-property: transform
	}

.navigation .navigation__hamburger::after,
		.navigation .navigation__hamburger::before {
			transition: 0.4s;
			transition-property: transform, opacity;
			content: '';
			border-radius: 1rem;
			position: absolute;
			width: 100%;
			height: var(--hamburger-bar-height);
			background-color: var(--hamburger-bar-color);
			transform-origin: center;
		}

.navigation .navigation__hamburger::after {
			transform: translateY(0.4rem);
		}

.navigation .navigation__hamburger::before {
			transform: translateY(-0.4rem) scale(1);
		}

.navigation .navigation__hamburger--close {
			transform: rotate(45deg)
		}

.navigation .navigation__hamburger--close::after {
				transform: translateY(0) rotate(-90deg);
			}

.navigation .navigation__hamburger--close::before {
				transform: translateY(0) rotate(-90deg);
			}

.navigation__background-toggle {
		pointer-events: none;
		position: fixed;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		max-height: 100vh;
		z-index: 10;
		-webkit-backdrop-filter: blur(0);
		        backdrop-filter: blur(0);
		transition: -webkit-backdrop-filter var(--transition-duration) var(--ease-out-cubic);
		transition: backdrop-filter var(--transition-duration) var(--ease-out-cubic);
		transition: backdrop-filter var(--transition-duration) var(--ease-out-cubic), -webkit-backdrop-filter var(--transition-duration) var(--ease-out-cubic)
	}

.navigation__background-toggle:hover {
			will-change: backdrop-filter;
		}

.navigation__background-toggle:hover ~ .navigation__items {
				will-change: transform, box-shadow;
			}

.navigation__toggle-checkbox:checked ~ .navigation__items {
				transform: translateX(0);
				box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.2), -2px 2px 2px rgba(0, 0, 0, 0.2),
					-4px 4px 4px rgba(0, 0, 0, 0.2), -8px 8px 8px rgba(0, 0, 0, 0.2), -16px 16px 16px rgba(0, 0, 0, 0.2);
			}

.navigation__toggle-checkbox:checked ~ .navigation__background-toggle {
				pointer-events: all;
				display: block;
				-webkit-backdrop-filter: blur(10px);
				        backdrop-filter: blur(10px);
			}

.navigation__items {
		position: fixed;
		display: flex;
		flex-direction: column;
		padding-top: var(--header-height);
		overflow: auto;
		overscroll-behavior-y: none;
		top: 0;
		right: 0;
		height: 100%;
		max-height: 100vh;
		min-width: 60vw;
		max-width: 100vw;
		box-shadow: none;
		transform: translateX(100%);
		transition: transform var(--transition-duration) var(--ease-out-cubic);
		z-index: 110;
		background-color: var(--color-blue--super-dark);
	}

.navigation__item {
		font-size: var(--font-size-m);
		color: var(--color-white)
	}

.navigation__item:last-child {
			margin-bottom: var(--spacing);
		}

.navigation__item--active {
			--link-color: var(--color-cyan);
		}

.navigation__item--imprint {
			display: flex;
			align-items: flex-end;
			justify-content: flex-end;
			flex-grow: 1;
			flex-shrink: 0;
			font-size: var(--font-size-s);
			padding-top: 0;
			padding-bottom: 0;
		}

.navigation__item--imprint .navigation__link-text {
				justify-content: flex-end
			}

.navigation__item--imprint .navigation__link-text::after {
					content: none;
				}

.navigation__link {
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		text-decoration: none;
		padding: 0.5em var(--spacing) 0.5em 1em;
		cursor: pointer;
		background-color: var(--link-color, transparent);
	}

.navigation__icon {
		margin-right: var(--spacing);
	}

.navigation__link-text {
		display: flex;
		flex: 1;
		align-items: center;
		padding: 1em 0;
		position: relative
	}

.navigation__link-text::after {
			content: '';
			position: absolute;
			bottom: -0.5em;
			left: 0;
			width: 100%;
			height: 1px;
			background-color: rgba(255, 255, 255, 0.2);
		}

.navigation-title {
	/* stylelint-disable */
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	/* stylelint-enable */
	word-break: break-word;
	font-family: var(--font-alternative-medium);
	text-align: center;
	font-size: var(--font-size-m);
	text-overflow: ellipsis;
	overflow: hidden;
	max-width: 100%;
	max-height: 100%;
}

.logo {
	display: grid;
	align-items: center;
	grid-gap: 0.5rem;
	grid-auto-flow: column;
}

.logo img {
		width: 2.25rem;
	}

.back-button {
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	transition: opacity 0.25s var(--ease-in-out-quad);
}

.back-button--hidden {
		pointer-events: none;
		opacity: 0;
	}
